import React, { Component } from 'react';
import { Location } from '@reach/router';


class HostPage extends Component {
    render() {
        const { location } = this.props;
        return (
                <div>{JSON.stringify(location)}</div>
        )
    }
}

export default HostPage
